/*Les animations*/
@font-face {
  font-family: funFont;
  src: url("fonts/fawn.ttf");
}

@keyframes degradeTitre {
  from {
    background-color: yellow;
    color: red;
  }
  25% {
    color: yellow;
    background-color: green;
  }
  50% {
    color: red;
    background-color: yellow;
  }
  75% {
    color: yellow;
    background-color: red;
  }
  to {
    background-color: yellow;
    color: red;
  }
}

@keyframes degradeTexte {
  from {
    color: red;
  }
  25% {
    color: yellow;
  }
  50% {
    color: #00ff15;
  }
  75% {
    color: yellow;
  }
  to {
    color: red;
  }
}

@keyframes cadreArcEnCiel {
  from {
    border: 6px solid white;
  }
  20% {
    border: 6px solid red;
  }
  40% {
    border: 6px solid yellow;
  }
  60% {
    border: 6px solid grey;
  }
  80% {
    border: 6px solid purple;
  }
  to {
    border: 6px solid white;
  }
}
