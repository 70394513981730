@import "animations.scss";

$mainFont: Arial, Helvetica, sans-serif;
$titleFont: "funFont";
$textColor: #222;

$bgColor: #2cb5e8;
$buttonColor: #eee;

$titleColor: #222a;
$titleShadowColor: darken($bgColor, 20%);

/*Le corps*/
body {
  font-family: $mainFont;
  color: $textColor;
  background-color: $bgColor;
  background-image: linear-gradient(
    141deg,
    #9fb8ad 0%,
    #1fc8db 51%,
    #2cb5e8 75%
  );
  cursor: crosshair;
  text-align: center;
  text-decoration: italic;
  font-size: 110%;
}

html,
body,
#root {
  height: 100%;
}

$header-shadow: 2px;
header {
  h1 {
    font-family: $titleFont;
    font-size: 11vw;
    color: $titleColor;
    letter-spacing: 0.6rem;
  }
  text-align: center;

  text-shadow: $titleShadowColor 0 0 0, $titleShadowColor 0 0 0,
    $titleShadowColor 0 0 0, $titleShadowColor 4px 4px 3px;
}

.title-font {
  font-family: $titleFont;
  color: $titleColor;
  letter-spacing: 0.25rem;
}

.center {
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}

.pure-button-primary {
  background-color: complement($buttonColor);
  color: complement($color: $textColor);
}

.full-height {
  height: 100%;
}

.chatContainer {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;

  .chatContent {
    /* grow or shrink as required from flex-basis height of 20% */
    flex: 1 1 20%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /* do not grow or shrink with a flex-basis height of 80% */
  .chatInput {
    fieldset {
      padding-bottom: 0;
    }
  }
}

.fullPageContainer {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  header {
    /* grow or shrink as required from flex-basis height of 20% */
    //flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-top: 1em;
  }

  .fullPageContent {
    flex: 1 1 15%;
    align-items: center;
  }
}

.background-left {
  background-color: lighten($bgColor, 30%);
}

.background-left-sm {
  background-color: lighten($bgColor, 30%);
  padding: 0.1em 1em;
  box-shadow: 4px 4px 8px lighten($bgColor, 10%);
}

.background-right {
  background-color: lighten($bgColor, 25%);
}

.background-center {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: lighten($bgColor, 20%);
  border: 1px solid #666;
  border-top: 0px;
  border-bottom: 0px;
}

.button-success,
.button-error {
  color: white;
  border-radius: 4px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.button-success {
  background: rgb(28, 184, 65);
}

.button-error {
  background: rgb(202, 60, 60);
}

.button-mini {
  font-size: 60%;
}

@media screen and (min-width: 64em) {
  .sm-show {
    display: none;
  }

  .background-right {
    display: flex !important;
  }
}

@media screen and (max-width: 64em) {
  .sm-hide {
    display: none;
  }

  .sm-show {
    position: absolute;
    letter-spacing: normal;
    z-index: 1;
  }

  .topRight {
    top: 3vh;
    right: 3vw;

    p {
      margin: 0;
    }
  }

  .stuckRight {
    position: absolute;
    top: 0vh;
    left: 100vw;
    right: 200vw;
    bottom: 100vh;
    width: 100vw;

    p {
      margin-top: 0vh;
    }

    .background-right-sm {
      background-color: lighten($bgColor, 25%);
      margin-top: 0px;
      display: flex;
      flex-direction: column;
      max-height: 95vh;
    }
  }

  .topLeft {
    top: 3vh;
    left: 3vw;

    p {
      margin: 0;
    }
  }

  .hugeOnSmall {
    width: 90%;
  }
}

.pure-form .pure-button[type="submit"] {
  margin-top: 0;
}
